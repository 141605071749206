var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "auth-form-wrap"
  }, [_c('div', {
    staticClass: "auth-form"
  }, [_c('h2', [_vm._v("Enter OTP")]), _c('div', {
    staticClass: "otp-form mb-5"
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": _vm.errorClass + ' otp-input',
      "separator": "",
      "num-inputs": 4,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-complete": _vm.onChangeOTP
    }
  })], 1), _c('button', {
    staticClass: "submit-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.verifyToken
    }
  }, [_vm.formLoader ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Verify Code")], 1), _c('OTPTimer', {
    attrs: {
      "title": "Didn't get Code? Resend Code",
      "expirySecond": 60
    },
    on: {
      "sendOTP": _vm.sendOtpCode
    }
  })], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-6 no-padding"
  }, [_c('div', {
    staticClass: "left-bg-wrapper text-center"
  }, [_c('h1', [_vm._v("Forgot your Password?")]), _c('p', [_vm._v("Don't Worry! we've got your back. Just follow the instructions and you'll be good :)")])])]);
}]

export { render, staticRenderFns }